<template>
    <div>

        <el-main style=" padding-top: 5px;padding-bottom: initial  ">
            <el-row>
                <el-col :lg="24">
                    <div :class='[this.$store.getters.getRunTimeTest ? "warning" : "tip"]'>
                        <el-row>
                            <el-col :lg="10" style="line-height: 27px;">
                                <strong style=" font-size: 16px; ">{{this.$store.getters.getRunTimeTest ?'(测试环境,数据定期删除)':''}}当前：打印-出纳凭证</strong>
                            </el-col>
                        </el-row>
                    </div>
                    <el-row style="margin-bottom: 20px">
                        <el-button type="primary" size="mini" @click="lodopPrint()" style="margin-bottom: 2px;margin-top: 2px">针式插件打印
                        </el-button>
                        <el-button type="primary" size="mini" @click="$router.back()" style="margin-bottom: 2px;margin-top: 2px">返回
                        </el-button>
                        <el-button type="primary" size="mini" @click="lodopPrint2()" style="margin-bottom: 2px;margin-top: 2px">直接针式打印
                        </el-button>
                        <el-tag type="danger" style="margin-left: 20px">打印次数：{{formData.print_count}}</el-tag>
                        <el-tag type="success" style="margin-left: 20px">最后打印日期：{{formData.print_last_date}}</el-tag>
                    </el-row>
                </el-col>
            </el-row>
            <div style="width: 100%;margin-right: 50px;float: left" v-for="index of pageSize" :key="index">
                <div id="myMain" style="width: 100%" ref="myMain">
                    <div :id="'tableDataMain'+index">
                        <div class="topHtml">
                            <div class="my-list-row">
                                <div class="my-list-col" style="width: 130mm;">
                                    <div class="my-list-row">
                                    <strong style=" font-size: 7mm ;margin-left: 80mm">{{formData.cash_type == 'YBFYD' || formData.cash_type == 'SKD' || formData.cash_type == 'ZJRZ' ? '收款凭证' : formData.cash_type == 'FKD' ? '付款凭证' : '收款凭证'}}</strong>
                                        <img border="0" src="@/assets/test.png" v-if="$store.getters.getRunTimeTest"
                                             style="z-index: -1; position: absolute; left:100px; top:200px;" />
                                        <img border="0" src="@/assets/zf.png" v-if="formData.is_cancel"
                                             style="z-index: -1; position: absolute; left:200px; top:150px;" />
                                    </div>
                                </div>
                            </div>
                            <!--{{formData.cash_type == 'YBFYD' ? '贷方科目' : '借方科目'}}-->
                            <div class="my-list-row">
                                <div class="my-list-col" style="width: 200mm;">
                                    <!--<a style="width: 140mm;">借方科目:</a>-->
                                    <a style="width: 140mm;" >第{{index}}页，共{{pageSize}}页。</a>
                                    <a style="width: 30mm;float: right;">字第{{formData.cash_type_id != '' && formData.cash_type_id != null ? '    '+formData.cash_type_id +'    ':'          '}}号</a>
                                    <a style="width: 90mm;float: right;">{{formatDate(formData.cash_date,'yyyy 年 M 月 d 日')}}</a>

                                </div>
                            </div>
                        </div>
                        <table cellspacing="0" style="width: 204mm;border: none">
                            <tr>
                                <th v-if="formData.cash_type == 'YBFYD'" style="height: 5mm;width: 40mm;border: solid 0.3mm;border-right: 0mm;border-bottom: 0mm;text-align: center !important;">备注</th>
                                <th v-if="formData.cash_type == 'SKD'|| formData.cash_type == 'ZJRZ'  || formData.cash_type == 'YBFYD' || formData.cash_type == 'QTSRD' || formData.cash_type == 'SDJ'" style="height: 5mm;width: 40mm;border: solid 0.3mm;border-bottom: 0mm;text-align: center !important;">付款人名称</th>
                                <th v-if="formData.cash_type == 'FKD'" style="height: 5mm;width: 40mm;border: solid 0.3mm;border-bottom: 0mm;text-align: center !important;">收款人名称</th>
                                <th style="height: 5mm;width: 35mm;border: solid 0.3mm;border-bottom: 0mm;border-left: 0mm;text-align: center !important;">{{formData.cash_type == 'FKD'? '付': '收'}}款行账户</th>
                                <th style="height: 5mm;width: 35mm;border: solid 0.3mm;border-bottom: 0mm;border-left: 0mm;text-align: center !important;">{{formData.cash_type == 'FKD'? '付': '收'}}款金额</th>
                                <th style="height: 5mm;width: 35mm;border: solid 0.3mm;border-bottom: 0mm;border-left: 0mm;text-align: center !important;">{{formData.cash_type == 'SKD' || formData.cash_type == 'ZJRZ' || formData.cash_type == 'FKD' || formData.cash_type == 'YBFYD' || formData.cash_type == 'QTSRD' || formData.cash_type == 'SDJ' ? '备 注' : ''}}</th>
                                <!--<th rowspan="2" style="height: 5mm;width: 5mm;border: solid 0.3mm;border-bottom: 0mm;border-left: 0mm;text-align: center !important;">记账</th>-->
                            </tr>
                            <tr style="width: 204mm; height: 10mm; " v-for="item in tableDataMainList[index-1]" :key="item.order_id">
                                <!--<td style="text-align: center !important;border: solid 0.3mm;border-bottom: 0mm;"><a>{{item.order_id}}</a></td>-->
                                <td v-if="formData.cash_type == 'YBFYD'" style="text-align: center !important;border: solid 0.3mm;border-right: 0mm;border-bottom: 0mm;"><a>{{item.cash_data_remark}}</a></td>
                                <td v-if="formData.cash_type == 'SKD'|| formData.cash_type == 'ZJRZ'  || formData.cash_type == 'FKD' || formData.cash_type == 'YBFYD' || formData.cash_type == 'QTSRD' || formData.cash_type == 'SDJ'" style="text-align: center !important;border: solid 0.3mm;border-bottom: 0mm;"><a>{{item.money_cash_data != null && item.money_cash_data != '' ? formData.company_name : ''}}</a></td>
                                <td v-if="formData.cash_type == 'SKD'|| formData.cash_type == 'ZJRZ'  || formData.cash_type == 'FKD' ||  formData.cash_type == 'SDJ'" style="text-align: center !important;border: solid 0.3mm;border-bottom: 0mm;border-left: 0mm;"><a>{{item.subject_name_cash_data}}</a></td>
                                <td v-if="formData.cash_type == 'QTSRD' || formData.cash_type == 'YBFYD'" style="text-align: center !important;border: solid 0.3mm;border-bottom: 0mm;border-left: 0mm;"><a>{{item.money_cash_data != null && item.money_cash_data != '' ? item.subject_name_cash_data +' ' + footerData[index-1].subject_name_cash_data : ''}}</a></td>
                                <td style="text-align: center !important;border: solid 0.3mm;border-bottom: 0mm;border-left: 0mm;"><a>{{item.money_cash_data != null && item.money_cash_data != '' ? '¥'+formatMoney(formData.cash_type == 'YBFYD' && item.money_cash_data &lt; 0 ? 0 - item.money_cash_data : item.money_cash_data):''}}</a></td>
                                <td style="text-align: center !important;border: solid 0.3mm;border-bottom: 0mm;border-left: 0mm;"><a>{{formData.cash_type == 'SKD'|| formData.cash_type == 'ZJRZ'  || formData.cash_type == 'FKD' || formData.cash_type == 'YBFYD' || formData.cash_type == 'QTSRD' || formData.cash_type == 'SDJ' ? item.summary_cash_data : ''}}</a></td>
                                <!--<td style="text-align: right !important;border: solid 0.3mm;border-bottom: 0mm;border-left: 0mm;"><a></a></td>-->
                            </tr>

                            <tr>
                                <td style="border-bottom: solid 0.3mm;border-top: solid 0.3mm;border-left: solid 0.3mm;border-right: solid 0.3mm;">
                                    <a style="margin-left: 5mm">附件</a>
                                    <a style="margin-left: 10mm">张</a>
                                </td>
                                <td style="border-bottom: solid 0.3mm;border-top: solid 0.3mm;border-right: solid 0.3mm;">
                                    <a style="margin-left: 15mm">合计金额</a>
                                </td>
                                <td style="text-align: center !important;border-bottom: solid 0.3mm;border-top: solid 0.3mm;border-right: solid 0.3mm;">{{formData.total_amount != null && formData.total_amount != '' ? '¥'+formatMoney(formData.cash_type == 'YBFYD'? 0 - formData.total_amount : formData.total_amount):0}}</td>
                                <td style="text-align: center !important;border-bottom: solid 0.3mm;border-top: solid 0.3mm;border-right: solid 0.3mm;"></td>
                                <td v-if="formData.cash_type == 'YBFYD'" style="text-align: center !important;border-bottom: solid 0.3mm;border-top: solid 0.3mm;border-right: solid 0.3mm;"></td>
                            </tr>

                        </table>
                        <!--<a style="width: 50mm;float: right" >第{{index}}页，共{{pageSize}}页。</a>-->
                        <div class="my-list-row" style="width: 204mm;">
                            <a style="width: 50mm">会计主管</a>
                            <!--<a style="margin-left:30mm;width: 50mm">记账</a>-->
                            <a style="margin-left:45mm;width: 50mm">审核</a>
                            <a style="margin-left:45mm;width: 50mm">出纳</a>
                            <a style="margin-left:45mm;width: 50mm">制证 {{formData.registrar}}</a>

                        </div>
                    </div>

                </div>
            </div>

        </el-main>
    </div>
</template>

<script>

    export default {

        data() {
            return {
                tableDataMain: [],
                tableDataMainList: [],
/*                tableDataMain1: [],
                tableDataMain2: [],
                tableDataMain3: [],*/
                footerData: [],
                checkList: [],
                pageSize:1,
                dataSize:0,
                formData: {
                    id: '',//收款单 ID
                    year_month : new Date(),//年月
                    year: '',//年份
                    month: '',//月份
                    type: 'SKD',//收款单类型 SKD、FKD
                    total_amount: 0,//合计
                    paid_up : 0,//实收金额
                    total_balance_money : 0,//合计冲账金额 凭证折扣
                    hang_money : 0,
                    balance: 0,
                    dispens_ability : '',//可分配金额
                    allocated_amount: '',//已分配金额
                    cash_type_id : '',//收款单对应不同类型的 ID
                    cash_type : 'SKD',//收款单类型 SKD、ZPRZ、ZJRZ、SDD
                    cash_date: new Date(),//录单日期
                    handled_by: '',//经手人
                    company_name : '',//付款单位
                    company_id : '',//付款单位Id
                    remark_cash: '',//备注
                    registrar: '',//记录人
                    additional_remark: '',//附加说明
                    last_name_cash: '',//最后修改人
                    last_date_cash: '',//最后修改日期
                    create_date: '',//创建日期
                    is_cancel: false,//作废
                    is_delete : false,//删除
                    print_count : 0,//
                    print_last_date : '',//
                    //excessToAdvance: '',//超收转订金
                    //idSDJ: '',//超收转订金(单号)
               },
                input:''
           }
       },
        methods: {
            lodop(){
                //体温单打印
                const LODOP = this.$getLodop();
                const strStyle = "<style>.my-list-row {display: inline-block; width: 100%;} .my-list-col {float: left; width: 33.33%;} .my-top,.my-bottom {font-size: 12px;} .my-top {margin-bottom: 5px;} .my-bottom {margin-top: 30px; text-align: right;}</style>"
                LODOP.SET_PRINT_PAGESIZE(1,2100,1400,"");//这里3表示纵向打印且纸高“按内容的高度”；1385表示纸宽138.5mm；45表示页底空白4.5mm
                for (let i = 1; i < this.pageSize + 1; i++) {
                    if (i > 1) {
                        LODOP.NewPageA();
                    }
                    const strHTML= strStyle+"<body>"+document.getElementById("tableDataMain" + i).innerHTML+"</body>";
                    LODOP.SET_PRINT_MODE("PRINT_PAGE_PERCENT","Auto-Width");
                    LODOP.ADD_PRINT_HTM(20,0,"100%","100%",strHTML);
                }
                /*if (this.pageSize > 1){
                    //LODOP.SET_PRINT_STYLEA(0,"AngleOfPageInside",90);
                    const strHTMLTwo = strStyle+"<body>"+document.getElementById("tableDataMain2").innerHTML+"</body>";
                    LODOP.NewPageA();
                    //LODOP.SET_PRINT_PAGESIZE(3,2000,1,"");//这里3表示纵向打印且纸高“按内容的高度”；1385表示纸宽138.5mm；45表示页底空白4.5mm
                    LODOP.SET_PRINT_PAGESIZE(1,2100,1400,"");//这里3表示纵向打印且纸高“按内容的高度”；1385表示纸宽138.5mm；45表示页底空白4.5mm
                    LODOP.ADD_PRINT_HTM(20,0,"100%","100%",strHTMLTwo);
                    //LODOP.ADD_PRINT_HTM(0,0,"100%","100%",strHTMLTwo);
                    //LODOP.SET_PRINT_STYLEA(0,"AngleOfPageInside",90);
               }
                if (this.pageSize > 2){
                    //LODOP.SET_PRINT_STYLEA(0,"AngleOfPageInside",90);
                    const strHTMLThree = strStyle+"<body>"+document.getElementById("tableDataMain3").innerHTML+"</body>";
                    LODOP.NewPageA();
                    //LODOP.SET_PRINT_PAGESIZE(3,2000,1,"");//这里3表示纵向打印且纸高“按内容的高度”；1385表示纸宽138.5mm；45表示页底空白4.5mm
                    LODOP.SET_PRINT_PAGESIZE(1,2100,1400,"");//这里3表示纵向打印且纸高“按内容的高度”；1385表示纸宽138.5mm；45表示页底空白4.5mm
                    LODOP.ADD_PRINT_HTM(20,0,"100%","100%",strHTMLThree);
                    //LODOP.ADD_PRINT_HTM(0,0,"100%","100%",strHTMLTwo);
                    //LODOP.SET_PRINT_STYLEA(0,"AngleOfPageInside",90);
               }*/
                return LODOP;
           },
            splitTableDataMain(){
               /* this.tableDataMain1 = [];
                this.tableDataMain2 = [];
                this.tableDataMain3 = [];
                for (let i = 0; i < 9; i++) {
                    this.tableDataMain1.push(this.tableDataMain[i]);
               }
                for (let i = 9; i < 18; i++) {
                    this.tableDataMain2.push(this.tableDataMain[i]);
               }
                for (let i = 18; i < 27; i++) {
                    this.tableDataMain3.push(this.tableDataMain[i]);
               }
                this.tableDataMainList.push(this.tableDataMain1);
                this.tableDataMainList.push(this.tableDataMain2);
                this.tableDataMainList.push(this.tableDataMain3);*/
                let list = [];
                for (let i = 0; i < this.tableDataMain.length; i++) {
                    list.push(this.tableDataMain[i]);
                    if (list.length == 9) {
                        this.tableDataMainList.push(list);
                        list = [];
                    }
                }
                list = this.$_createRowDataByReceipt(list,9);
                this.tableDataMainList.push(list);
           },
            lodopPrint(){

                    this.$axios({
                        method: 'post',
                        url: '/admin/receipt/print',
                        params: {
                            id:this.formData.id
                        }
                    }).then(() => {         //这里使用了ES6的语法
                        this.searchReceipt();
                        const LODOP = this.lodop()
                        setTimeout(() => {
                            LODOP.PREVIEW()
                        }, 500 )
                    })
           },
            lodopPrint2(){
                this.$axios({
                    method: 'post',
                    url: '/admin/receipt/print',
                    params: {
                        id:this.formData.id
                    }
                }).then(() => {         //这里使用了ES6的语法
                this.searchReceipt();
                const LODOP = this.lodop()
                LODOP.PRINT();
                //LODOP.PREVIEW()
                //LODOP.PRINT_SETUP();//打印维护
                //LODOP.PRINT_DESIGN()
                })
            },
            indexMethod(index) {
                return index +1;
           },

            //查询收款单
            async searchReceipt() {
                const loading = this.$loading({
                    lock: true,
                    text: '饮茶先啦，数据处理中，请耐心等待!',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
               });
                await this.$axios({
                    method: 'post',
                    url: '/admin/receipt/searchReceipt',
                    params: {
                        id: this.formData.id,
                        cash_type_id : this.formData.cash_type_id,
                        type: this.formData.type,
                        year: this.formData.year,
                        month: this.formData.month
                   },
               }).then((response) => {         //这里使用了ES6的语法
                    ////console.log(response)       //请求成功返回的数据
                    loading.close();
                    if (response.status === 200 && response.data.state == 'ok') {
                        this.formData = response.data.formData;
                        this.footerData = response.data.footerData;
                        //this.dataSize = response.data.tableDataMain.length;
                        this.tableDataMain = response.data.tableDataMain;
                        this.dataSize = response.data.tableDataMain.length;
                        this.pageSize = this.dataSize % 9 == 0 ? this.dataSize / 9 : this.$XEUtils.toInteger((this.dataSize / 9)) + 1;
                        this.splitTableDataMain();
                        //this.tableDataMain = this.$_createRowDataByReceipt(response.data.tableDataMain);
                        //console.log('dataSize',this.tableDataMain.length)
                        /*if (this.dataSize > 10){
                            this.pageSize = 2;
                       }
                        if(this.dataSize > 20){
                            this.pageSize = 3;
                       }*/
                   } else {
                        //this.$XModal.alert(response.data.msg)
                        this.disableGrid = true;
                        this.$message({
                            showClose: true,
                            message: response.data.msg,
                            type: 'error'
                       });
                   }

               }).catch((error) => {
                    loading.close();
                    console.log(error)
               });
                //return Promise.resolve();
           },
       },
        created() {
            this.formData.id = this.$route.query.id;
            this.searchReceipt();
       }
   }


</script>

<style scoped>
    th, td {
        text-align: initial !important;
   }
    .my-list-row {
        display: inline-block;
        width: 100%;
   }
    .my-list-col {
        float: left;
        width: 33.33%;
   }
    .my-top,.my-bottom {
        font-size: 12px;
   }
    .my-top {
        margin-bottom: 5px;
   }
    .my-bottom {
        margin-top: 30px;
        text-align: right;
   }
</style>
